import { useMutation } from "@tanstack/vue-query";

export const useMiddleware = defineStore("middleware", () => {
  const { $axios, $reportError } = useNuxtApp();

  const { mutateAsync: subdomain, isSuccess: isSubdomain } = useMutation({
    mutationFn: async (data: any) =>
      await $axios.get(`/v1/accounts/${data}`).then((res) => res.data),
    onSuccess: (data) => data,
    onError: (error) => $reportError("Middleware store", error),
  });

  const {
    mutateAsync: account,
    isSuccess: isAccountSuccess,
    isError: isAccountError,
  } = useMutation({
    mutationFn: async () =>
      await $axios.get(`/v1/account`).then((res) => res.data),
    onSuccess: (data) => data,
    onError: (error) => $reportError("Middleware store", error),
  });

  const { mutateAsync: session } = useMutation({
    mutationFn: async (data: any) =>
      await $axios.get(`/v1/sessions/${data}`).then((res) => res.data),
    onSuccess: (data) => data,
    onError: (error) => $reportError("Middleware store", error),
  });

  const { mutateAsync: checkToken, isSuccess: isTokenSuccess } = useMutation({
    mutationFn: async (data) => {
      await $axios.get("/sanctum/csrf-cookie");
      return await $axios
        .post("/reset-password/valid-token", {
          token: data.token,
          email: data.email,
        })
        .then((res) => res.data);
    },
    onSuccess: (data) => data,
    onError: (error: any) => $reportError("Middleware store", error),
  });

  return {
    checkToken,
    isTokenSuccess,
    session,
    account,
    isAccountSuccess,
    isAccountError,
    subdomain,
    isSubdomain,
  };
});
