import revive_payload_client_XEGlFfrNcr from "/opt/build/repo/node_modules/.pnpm/nuxt@3.10.1_@parcel+watcher@2.5.1_@types+node@22.13.1_magicast@0.3.5_rollup@4.30.1_sass@1.83._fp22d55yaara6ku6d3fqou2a4i/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_5FuoWvDGxn from "/opt/build/repo/node_modules/.pnpm/nuxt@3.10.1_@parcel+watcher@2.5.1_@types+node@22.13.1_magicast@0.3.5_rollup@4.30.1_sass@1.83._fp22d55yaara6ku6d3fqou2a4i/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jpdaeJi7mq from "/opt/build/repo/node_modules/.pnpm/nuxt@3.10.1_@parcel+watcher@2.5.1_@types+node@22.13.1_magicast@0.3.5_rollup@4.30.1_sass@1.83._fp22d55yaara6ku6d3fqou2a4i/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_Wnps4HXOKt from "/opt/build/repo/node_modules/.pnpm/nuxt@3.10.1_@parcel+watcher@2.5.1_@types+node@22.13.1_magicast@0.3.5_rollup@4.30.1_sass@1.83._fp22d55yaara6ku6d3fqou2a4i/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_P3mDhhE2rH from "/opt/build/repo/node_modules/.pnpm/nuxt@3.10.1_@parcel+watcher@2.5.1_@types+node@22.13.1_magicast@0.3.5_rollup@4.30.1_sass@1.83._fp22d55yaara6ku6d3fqou2a4i/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_jEpRIyIQxy from "/opt/build/repo/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.30.1_typescript@5.7.3_vue@3.5.13_typescript@5.7.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_G1d69mMSE3 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.10.1_@parcel+watcher@2.5.1_@types+node@22.13.1_magicast@0.3.5_rollup@4.30.1_sass@1.83._fp22d55yaara6ku6d3fqou2a4i/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_qFlxyh7BEr from "/opt/build/repo/node_modules/.pnpm/@sentry+nuxt@9.0.0_@opentelemetry+api@1.9.0_@opentelemetry+context-async-hooks@1.30.1_@opente_o7cxmaqyorcpon6vybp4sw3rqe/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/opt/build/repo/.nuxt/sentry-client-config.mjs";
import plugin_2AJejWfWY2 from "/opt/build/repo/node_modules/.pnpm/nuxt-bugsnag@7.3.0_@bugsnag+core@8.2.0_magicast@0.3.5_rollup@4.30.1/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import slideovers_z43nX4qz4b from "/opt/build/repo/node_modules/.pnpm/@nuxt+ui@2.20.0_axios@1.6.7_change-case@5.4.4_focus-trap@7.6.4_magicast@0.3.5_rollup@4.30.1_t_wbirmq5fornj6bqsvunyibsmt4/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_7FBeCSrx9f from "/opt/build/repo/node_modules/.pnpm/@nuxt+ui@2.20.0_axios@1.6.7_change-case@5.4.4_focus-trap@7.6.4_magicast@0.3.5_rollup@4.30.1_t_wbirmq5fornj6bqsvunyibsmt4/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_xzTnQ4IB11 from "/opt/build/repo/node_modules/.pnpm/@nuxt+ui@2.20.0_axios@1.6.7_change-case@5.4.4_focus-trap@7.6.4_magicast@0.3.5_rollup@4.30.1_t_wbirmq5fornj6bqsvunyibsmt4/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_CS8Nw8Hh9h from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5_rollup@4.30.1/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_WPntOONo9A from "/opt/build/repo/node_modules/.pnpm/@nuxt+icon@1.10.3_magicast@0.3.5_rollup@4.30.1_vite@5.0.12_@types+node@22.13.1_sass@1.83.1_te_jpjbzd7ciy4oh77ku5q3uyz2ti/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import formkitPlugin_pZqjah0RUG from "/opt/build/repo/.nuxt/formkitPlugin.mjs";
import chunk_reload_client_YubQ7OwotK from "/opt/build/repo/node_modules/.pnpm/nuxt@3.10.1_@parcel+watcher@2.5.1_@types+node@22.13.1_magicast@0.3.5_rollup@4.30.1_sass@1.83._fp22d55yaara6ku6d3fqou2a4i/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import axios_QMFgzss1s4 from "/opt/build/repo/plugins/axios.ts";
import echo_Too0shxWI0 from "/opt/build/repo/plugins/echo.ts";
import i18n_VfGcjrvSkj from "/opt/build/repo/plugins/i18n.ts";
import plyr_m1vubbxwTm from "/opt/build/repo/plugins/plyr.ts";
import sentry_3AyO8nEfhE from "/opt/build/repo/plugins/sentry.ts";
import stripe_6FZUdDHpIZ from "/opt/build/repo/plugins/stripe.ts";
import vue_final_modal_pML93k5qcp from "/opt/build/repo/plugins/vue-final-modal.ts";
import vue_query_wrmMkNpEpe from "/opt/build/repo/plugins/vue-query.ts";
export default [
  revive_payload_client_XEGlFfrNcr,
  unhead_5FuoWvDGxn,
  router_jpdaeJi7mq,
  payload_client_Wnps4HXOKt,
  check_outdated_build_client_P3mDhhE2rH,
  plugin_vue3_jEpRIyIQxy,
  components_plugin_KR1HBZs4kY,
  prefetch_client_G1d69mMSE3,
  sentry_client_qFlxyh7BEr,
  sentry_client_config_o34nk2sJbg,
  plugin_2AJejWfWY2,
  slideovers_z43nX4qz4b,
  modals_7FBeCSrx9f,
  colors_xzTnQ4IB11,
  plugin_client_CS8Nw8Hh9h,
  plugin_WPntOONo9A,
  formkitPlugin_pZqjah0RUG,
  chunk_reload_client_YubQ7OwotK,
  axios_QMFgzss1s4,
  echo_Too0shxWI0,
  i18n_VfGcjrvSkj,
  plyr_m1vubbxwTm,
  sentry_3AyO8nEfhE,
  stripe_6FZUdDHpIZ,
  vue_final_modal_pML93k5qcp,
  vue_query_wrmMkNpEpe
]