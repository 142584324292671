import * as Sentry from "@sentry/nuxt";
import { defineNuxtPlugin } from "#app";

/**
 * Nuxt.js plugin for integrating Sentry error tracking.
 *
 * This plugin sets up global error handling for Vue components and unhandled promise rejections,
 * and provides a method to manually report errors to Sentry.
 *
 * @param nuxtApp - The Nuxt application instance.
 *
 * @returns An object with a `reportError` method to manually report errors to Sentry.
 *
 * @example
 * // Usage in a Nuxt component
 * $reportError(new Error("Something went wrong"), { additionalInfo: "Some context" });
 */
export default defineNuxtPlugin((nuxtApp) => {
  /**
   * This function is called whenever an error is detected in a Vue component.
   *
   * @param error the error that was detected
   * @param instance the Vue component instance where the error was detected
   * @param info additional information about the error
   */
  nuxtApp.vueApp.config.errorHandler = (error, instance, info) => {
    console.error("Sentry plugin - Error detected:", error, instance, info);
    Sentry.captureException(error);
  };

  /**
   * This function is called whenever a promise fails.
   */
  window.addEventListener("unhandledrejection", (event) => {
    console.error("Sentry handler - Failed promise:", event.reason);
    Sentry.captureException(event.reason); // 🚀 Catch promise error and send to sentry
  });

  /**
   * This function can be called to manually report an error to Sentry. thought to replace the need to use `Sentry.captureException` and `Console.log,info,error`
   * (By the way it reports on console either).
   * @param error
   * @param context
   */
  const reportError = (
    error: Error | string,
    context?: Record<string, any>,
  ) => {
    const errorInstance = error instanceof Error ? error : new Error(error);

    if (context) {
      Sentry.setContext("additional", context);
    }

    Sentry.captureException(errorInstance);
    console.error("Manual error reported to Sentry:", errorInstance, context);
  };

  /**
   * this function is to report status info to the console with custom style color, this is useful to report status info to the console
   * reports only in non-production mode
   * @param message the message to report
   * @param error the color to use in the message (default is green) or the error to report
   */
  const reportStatusInfo = (message: string, ...error: any) => {
    const color = typeof error === "string" ? error : "green";
    const errorMessage = typeof error === "string" ? "" : error;
    if (process.env.NODE_ENV !== "production") {
      console.info(
        `%c${message}`,
        `color: ${color}; font-size: 16px; font-weight: bold;`,
        { context: errorMessage },
      );
    }
  };

  return {
    provide: {
      reportError,
      reportStatusInfo,
    },
  };
});
